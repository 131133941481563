* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px;
}

/* .navbar-area {
  border-bottom: 1px solid #000;
} */

.site-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.site-logo {
  margin: 1em;
  font-size: 1.5em;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: #000;
  text-decoration: none;
}

.site-navbar ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.site-navbar ul li a {
  color: #000;
  padding: 20px;
  display: block;
  text-decoration: none;
  font-size: 15px;
  letter-spacing: 0.1em;
}

.site-navbar ul li a:hover {
  color: rgba(0, 0, 0, 0.616);
}

/* Toggle Menu */
.site-navbar .menu-icon {
  color: red;
  cursor: pointer;
  display: none;
  display: inline-block;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}

.container .site-navbar .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
}

.site-navbar .menu-icon .navicon:before,
.site-navbar .menu-icon .navicon:after {
  background: #333;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.site-navbar .menu-icon .navicon:before {
  top: 5px;
}

.site-navbar .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.site-navbar .menu-btn {
  display: none;
}

.site-navbar .menu-btn:checked ~ .menu {
  max-height: 240px;
}

.site-navbar .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.site-navbar .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.site-navbar .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.site-navbar .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.site-navbar .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* @media (max-width: 768px) {
  
  
  
  .site-navbar .menu-icon {
    display: inline-block;
  }
} */
@media (max-width: 768px) {
  .site-navbar .menu {
    clear: none;
    float: right;
    max-height: none;
  }

  .site-navbar .menu li {
    float: left;
  }

  .site-navbar .menu li a {
    /* padding: 20px 10px; */
    text-align: center;
  }

  .site-navbar ul li a {
    color: #000;
    padding: 0;
    display: block;
    text-decoration: none;
    font-size: 15px;
    letter-spacing: 0.1em;
  }
}
