footer{
  margin: 2em 4em;
  float: right;
  font-size: 1em;;
}

@media (max-width: 768px) {
  footer {
    /* margin-top: 2em; */
    /* margin-bottom: 2em; */
    text-align: center;
    margin: 2em 1em;
    float: none;
    font-size: 1em;
  }
}