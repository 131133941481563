.intro-area {
  margin-left: auto;
  margin-right: auto;
  width: 500px;
  text-align: center;
  padding: 1em 0;
  letter-spacing: 0.08em;
}

.intro-container {
  /* border: 2px solid blue; */
  padding: 1em;
}

.intro-container h1 {
  margin: 1em;
  font-weight: 500;
  font-size: 40px;
}

.intro-container p {
  /* margin-top: 0; */
  margin-left: auto;
  margin-right: auto;
  /* padding-bottom: 3em; */
  font-weight: 400;
  font-size: 1.5em;
  /* color: #8f8f8f; */
  color: #757575;
  /* line-height: 1.8em; */
  line-height: 1.38em;
  letter-spacing: 0.1em;
}

/* @media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
{
  
  .intro-container p {
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 3em;
    padding-left: 1em;
    padding-right: 1em;
    font-weight: 400;
    font-size: 23px;
    color: #8f8f8f;
    /* line-height: 1.8em; */
/* line-height: 1.38em;
    letter-spacing: .08em;
  } */
/* } */

@media (max-width: 768px) {
  .intro-area {
    margin-left: auto;
    margin-right: auto;
    width: auto;
    text-align: center;
    padding: 0;
    letter-spacing: 0.08em;
  }

  .intro-container {
    display: block;
    margin-top: 0;
    margin-left: 0%;
    margin-right: 0%;
    text-align: center;
    padding-top: 0;
    letter-spacing: 0.08em;
  }

  .intro-container h1 {
    margin: 1em;
    font-weight: 500;
    font-size: 30px;
  }

  .intro-container p {
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 1em;
    /* padding-left: 1em; */
    /* padding-right: 1em; */
    font-weight: 400;
    font-size: 18px;
    /* color: #8f8f8f; */
    color: #757575;
    text-align: center;
    /* line-height: 1.8em; */
    line-height: 1.38em;
    letter-spacing: 0.08em;
  }
}

/* @media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px)
  {
    .intro-container{
      color:blue;
    }
  } */
