.quiz-image {
  /* padding-top:3em; */
  display: block;
  width: 90%;
  height: auto;
  padding-bottom: 5em;
  margin: 0 auto;
}

.container {
  width: 100%;
  /* margin:0; */
}
.container p {
  margin-top: 0.5em;
  padding-bottom: 5em;
  /* padding-top:2em; */
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 1.8em;
  letter-spacing: 0.08em;
  text-rendering: optimizeLegibility;
}
.screenshot {
  width: 70%;
  padding-bottom: 0.5em;
}
caption {
  display: flex;
  font-size: 0.9em;
}
.app-link {
  text-decoration: none;
  color: black;
  display: block;
  margin: auto;
  margin-bottom: 4em;
  text-align: center;
}
.app-link:hover {
  color: orangered;
}
.tech-header {
  text-align: center;
}
.tech-stack {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
}

li {
  padding: 0.5em;
}
.quiz-wrapper {
  margin-left: auto;
  margin-right: auto;
  width: 600px;
}

.quiz-img {
  /* width: 100%;
  margin-left: auto;
  margin-right: auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 4em;
}

/* btn */
#btn-container {
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
}

button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}
button.live-site {
  width: 12rem;
  height: auto;
}
button.live-site .circle {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #282936;
  border-radius: 1.625rem;
}
button.live-site .circle .icon {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}
button.live-site .circle .icon.arrow {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}
button.live-site .circle .icon.arrow::before {
  position: absolute;
  content: '';
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
button.live-site .button-text {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #282936;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}
button:hover .circle {
  width: 100%;
}
button:hover .circle .icon.arrow {
  background: #fff;
  -webkit-transform: translate(1rem, 0);
  transform: translate(1rem, 0);
}
button:hover .button-text {
  color: #fff;
}

/* .project-btn .live-btn {
  text-align: center;
  cursor: pointer;
  font-size:24px;
  margin: 0 0 0 100px;
  border-radius: 4px;
  background-color:#d35400;
  border: none;
  padding: 20px;
  width: 200px;
  transition: all 0.5s;
}

.project-btn .live-btn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.project-btn .live-btn span:after {
  content: '»';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.project-btn .live-btn:hover span {
  padding-right: 25px;
}

.project-btn .live-btn:hover span:after {
  opacity: 1;
  right: 0;
} */

@media (max-width: 768px) {
  .quiz-image {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .quiz-wrapper {
    width: auto;
  }

  .quiz-wrapper h2 {
    padding-left: 0.5em;
  }

  .quiz-wrapper p {
    margin: 0.5em;
    padding-bottom: 2em;
  }

  .quiz-img {
    padding-bottom: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .quiz-img figcaption {
    text-align: center;
  }

  .screenshot {
    width: 90%;
    padding-bottom: 0.5em;
  }
}
