.socials{
  margin: 0em 3em;
}

.social-link{
  margin: 0em 0.5em;
}

.socials svg:hover {
  border-bottom: 1px solid #00000038;
}

@media (max-width: 768px) {
  .socials {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* margin: 0em 3em; */
  }
}