/* .portfolio-container {
  /* margin-top: 2em; */
/* font-family: 'Nunito';
  display: flex;
  margin-left: 1em;
  margin-right: 1em; */
/* margin: 1em; */
/* } */

.portfolio-container {
  margin-left: 4em;
  margin-right: 4em;
  /* margin-top: 2em; */
  font-family: 'Nunito';
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  column-gap: 50px;
  row-gap: 0;
  justify-items: center;
  align-items: center;
  justify-content: center;
  /* display: flex; */
  /* margin-left: 1em; */
  /* margin-right: 1em; */
  /* margin: 1em; */
}

.portfolio-project {
  position: relative;
  /* width: 65%; */
  margin: 3em 1em;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(194, 195, 195, 0.85);
}

.portfolio-project:hover .overlay {
  opacity: 1;
}

.text {
  color: black;
  font-size: 2.25em;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.text a {
  display: inline-block;
  text-decoration: none;
  color: black;
  padding: 3em;
}

.project-container {
  height: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    to right,
    rgba(157, 157, 160, 0.801),
    rgba(214, 231, 229, 0.315)
  );
}

.project-img {
  width: 60%;
}

/* @media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
{
  .portfolio-container {
    margin-top: 0;
    font-family: 'Nunito';
    display: block;
    margin: 1em;
    background-color: red;
  }
  .portfolio-project {
    position: relative;
    width: 95%;
    padding: 0;
    margin-top: 0;
    margin-bottom: 3em;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px)
  {
    .portfolio-container {
      background-color: blue;
    }
  } */

@media (max-width: 768px) {
  .portfolio-container {
    margin-top: 0;
    font-family: 'Nunito';
    display: block;
    margin: 1em;
    /* background-color: red; */
  }

  .portfolio-project {
    position: relative;
    width: 95%;
    padding: 0;
    margin-top: 0;
    margin-bottom: 3em;
    margin-left: auto;
    margin-right: auto;
  }

  .text a {
    padding: 2em;
  }
}
