.about {
  /* margin-left: 9em;
  margin-right: 9em;
  margin-bottom: 3em;
  margin-top: 3em; */
  width: 70%;
  /* margin-left: auto; */
  /* margin-right: auto; */
  /* margin-top: 2em; */
  /* margin-bottom: 2em; */
  margin: 2em auto;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 1.8em;
  letter-spacing: .08em;
  text-rendering: optimizeLegibility;
}

.about h3 {
  font-weight: 400;
  font-size: 1.25em;
  padding-bottom: 1em;
}

.about-socials {
  margin-top: 5em;
  margin-left: -43px;
}

.about p {
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  line-height: 1.8em;
  letter-spacing: .08em;
  text-rendering: optimizeLegibility;
  padding-bottom: 1em;
}

.about-img img {
  width:50%;
}

.about-caption {
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 1.5px;
}
.about a {
  cursor: pointer;
  text-decoration: none;
  color: black;
  font-weight: 500;
}

.about a:hover {
  color: rgba(0, 0, 0, .7);
}

.wrapper {
  display:grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: minmax(100px, auto);
  grid-gap:1em;
  /* justify-items: stretch;
  align-items: stretch; */
  padding-top:4em;
  margin-left: 6em;
  margin-right: 6em;
  margin-bottom: 2em;
}

/* .wrapper > img {
  /* padding:1em; */


/* .box{
 width:60%;
} */

.img-small {
  grid-column-start: 1;
  width: 100%;
  /* grid-row:1/3; */
  /* width:60%; */
}
.img-tall {
  grid-column-start: 2;
  grid-row-end: span 3;
  width: 100%;

  /* grid-row:1/4; */
  /* padding-left: 16.5em; */
  /* width:50% */
}

.resume-btn {
  text-align: center;
  position:relative;
  /* margin-top:10%; */
  background: #defcff;
   border:1px solid black;
  padding:20px;
  font-size:0.9em;
  color:white;
  box-shadow:4px 4px 0px 0px black;
  font-family: 'Open Sans', sans-serif;
  font-weight:700;
  letter-spacing:5px;
  text-transform:uppercase;
  transition: all 300ms ease-in-out;
  left:0;
  top:0;
}
  
.resume-btn:hover {
  left:4px;
  top:4px;
  box-shadow:0 0 0 0 white;
}
  

@media (max-width: 768px) {
  .about {
    width: 75%;
    margin: 1em auto;
    /* padding-top: 7em; */
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 1.8em;
    letter-spacing: .08em;
    text-rendering: optimizeLegibility;
  }

  .about h3 {
    font-size: 1.25em;
    /* text-align: center; */
  }

  .about p {
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 1.8em;
    letter-spacing: .08em;
    text-rendering: optimizeLegibility;
  }

  .wrapper {
    display: block;
    padding-top: 2em;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 2em;
  }

  figure {
    display: flex;
    flex-direction: column;
    /* align-content: center; */
    align-items: center;
    padding-bottom: 3em;
  }

  .wrapper img {
    width: 74%;
    padding-bottom: 0.5em;
}
}



/* 
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
{
  .about {
    width: 75%;
    margin: 1em auto;
    padding-top: 7em; 
     padding-left: 2em;
    padding-right: 2em;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 1.8em;
    letter-spacing: .08em;
    text-rendering: optimizeLegibility;
  }

  .about h3 {
    font-size: 1em;
  }

  .about p {
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 1.8em;
    letter-spacing: .08em;
    text-rendering: optimizeLegibility;
  }

  .wrapper {
    display: block;
    margin: 1em auto;
  }
  
  .wrapper img {
    width: 80%;
  }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px)
  {
    
  } */